import Input from "@/components/input";
import Loader from "@/components/loader";
import neoSwitch from "@/components/toggle-switch";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import DatePicker from "vue2-datepicker";
import axios from "@/axios";
import {get} from "lodash";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {DateTime} from "luxon";
import {startCase, debounce} from "lodash";

export default {
    name: "ner-analyser",
    components: {
        "neo-input": Input,
        "neo-loader": Loader,
        neoSwitch,
        VueMultiselect,
        DatePicker,
    },
    props: {},
    data() {
        return {
            toggleNerSort: false,
            nerRunning: false,
            // allFilters - all filters that are to be populated within the dropdown
            allFilters: {
                location: [
                    {
                        group: "Location",
                        values: [],
                        allLoaded: false,
                        loading: false,
                    },
                ],
                dateRange: null,
                person: [
                    {
                        group: "Name",
                        values: [],
                        allLoaded: false,
                        loading: false,
                    },
                ],
                organisation: [
                    {
                        group: "Organisation",
                        values: [],
                        allLoaded: false,
                        loading: false,
                    },
                ],
            },
            // filters - selected filters values
            filters: {
                location: null,
                dateRange: null,
                person: null,
                organisation: null,
            },
            caseIdentifiersOnly: false,
            infiniteScrollDistance: 10,
        };
    },
    created() {
        this.searchFilters = debounce(this.searchFilters, 400, {
            leading: false,
            trailing: true,
        });
    },
    watch: {},
    async mounted() {
        this.filters = {
            location: this.nerFilters?.[`neo_location`],
            person: this.nerFilters?.[`neo_person`],
            dateRange: [],
            organisation: this.nerFilters?.[`neo_organisation`],
        };
        if (this.nerFilters?.["neo_from_date"] && this.nerFilters?.["neo_to_date"]) {
            this.filters.dateRange.push(this.nerFilters[`neo_from_date`]);
            this.filters.dateRange.push(this.nerFilters[`neo_to_date`]);
        }
    },
    beforeDestroy() {},
    computed: {
        ...mapGetters(["getCaseId", "nerFilters"]),

        formattedDate() {
            let formatDate = ["Starting Date", "Ending Date"];
            if (this.filters?.dateRange?.length) {
                let start = this.filters.dateRange[0];
                let end = this.filters.dateRange[1];

                if (start && end) {
                    start = DateTime.fromISO(start);
                    start = start.toFormat("dd/MM/yyyy");
                    end = DateTime.fromISO(end);
                    end = end.toFormat("dd/MM/yyyy");
                    formatDate = [start, end];
                }

                return formatDate;
            } else return formatDate;
        },
    },
    methods: {
        /**
         * @function
         * @name fetchFilterValues - fetch filter data & populate the filters dropdowns
         * @params keyword - filter parameter that needs to be populated
         *         event - search keyword that needs to be searched
         */
        async fetchFilterValues(keyword, event) {
            const payload = {
                case_id: this.getCaseId,
                keyword_type: keyword === "person" ? "name" : keyword,
                search_string: event,
                offset: this.allFilters[keyword][0].values?.length,
                limit: 100,
                case_identifiers_only: this.caseIdentifiersOnly,
            };
            const {data: filters} = await axios.post("/ner-analyzer/global-filter", payload);
            return filters;
        },

        /**
         * @function
         * @name loadMoreFilters - load more filters to the filters dropdowns
         * @params keyword - filter parameter that needs to be populated
         */
        async loadMoreFilters(keyword) {
            if (!this.allFilters[keyword][0]?.allLoaded && !this.allFilters[keyword][0]?.loading) {
                this.allFilters[keyword][0].loading = true;
                const data = await this.fetchFilterValues(keyword);
                this.allFilters[keyword][0].values = [...this.allFilters[keyword][0]?.values, ...data?.data];
                if (this.allFilters[keyword][0]?.values.length === data?.total_result_count) {
                    this.allFilters[keyword][0].allLoaded = true;
                }
                this.allFilters[keyword][0].loading = false;
            }
        },

        /**
         * @function
         * @name searchFilters - search filters in the filters dropdowns
         * @params keyword - filter parameter type that needs to be searched
         */
        async searchFilters(event, keyword) {
            this.allFilters[keyword][0].loading = true;
            this.allFilters[keyword][0].values = [];
            this.allFilters[keyword][0].allLoaded = false;
            const data = await this.fetchFilterValues(keyword, event);
            this.allFilters[keyword][0].values = [...data?.data];
            if (this.allFilters[keyword][0]?.values.length === data?.total_result_count) this.allFilters[keyword][0].allLoaded = true;
            this.allFilters[keyword][0].loading = false;
        },

        /**
         * @function
         * @name includeCaseIdentifiers - toggle case identifier to be included in filters
         */
        includeFilters() {
            this.caseIdentifiersOnly = !this.caseIdentifiersOnly;
            this.allFilters["location"][0].values = [];
            this.allFilters["person"][0].values = [];
            this.allFilters["organisation"][0].values = [];
            this.allFilters["location"][0].allLoaded = false;
            this.allFilters["person"][0].allLoaded = false;
            this.allFilters["organisation"][0].allLoaded = false;
            this.loadMoreFilters("location");
            this.loadMoreFilters("person");
            this.loadMoreFilters("organisation");
            if (this.caseIdentifiersOnly)
                this.filters = {
                    location: null,
                    dateRange: null,
                    person: null,
                    organisation: null,
                };
        },

        /**
         * @function
         * @name handleNer - run NER (either on selected tools/queries/cards or bulk analyse all tools/queries/cards
         * @params {isBulkAnalyse} - Boolean to check if NER is bulk analyse or not
         */
        handleNer(isBulkAnalyse) {
            this.$emit("handleNer");
        },

        /**
         * @function
         * @name applyNerFilters - apply global filters on tool level to filter queries / results
         */
        applyNerFilters() {
            let filter_data;
            for (const filter in this.filters) {
                if (filter !== "dateRange" && this.filters[filter]?.length)
                    filter_data = {
                        ...filter_data,
                        [`neo_${filter}`]: this.filters[filter],
                    };
                else if (filter === "dateRange" && this.filters[filter]?.length && this.filters[filter]?.[0] && this.filters[filter]?.[1]) {
                    filter_data = {
                        ...filter_data,
                        [`neo_from_date`]: this.filters[filter]?.[0],
                    };
                    filter_data = {
                        ...filter_data,
                        [`neo_to_date`]: this.filters[filter]?.[1],
                    };
                }
            }
            this.$emit("applyNerFilters", filter_data);
        },

        /**
         * @function
         * @name setDatefilter - set daterange filters
         */
        async setDatefilter(dt) {},

        /**
         * @function
         * @name handleNerClear - clear NER filters and show all data
         */
        handleNerClear() {
            this.filters = {
                location: null,
                dateRange: null,
                person: null,
                organisation: null,
            };
            this.applyNerFilters();
        },

        disabledRange(date) {
            return date > new Date();
        },

        limitText(length) {
            return ` +${length}`;
        },
    },
};
